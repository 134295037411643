<template>

    <div class="container-fluid main-color">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">Assessment Criteria Percentage Detail</h4>
          </div>
        </div>
      </div>
  
      <!-- end page title -->
      <div class="row">
        <div class="col-lg-12">
         
          <place-holder v-if="isLoading"></place-holder> 
          <div class="card">
            <div class="card-body">

              <form>
             
                    <div class="row mb-3">

                      <div class="col-lg-3">

                        <label for="name"> 
                            ID 
                        </label>

                      </div>

                      <div class="col-lg-6">
                        
                            <input 
                            name="name"
                            id="name"
                            type="text"  
                            class="form-control" 
                            v-model="formData.code"
                            disabled
                            />

                      </div>

                    </div>

                    <div class="row mb-3">

                      <div class="col-lg-3">
                        <label for="program"> 
                            Program Name 
                            <span class="text-danger">*</span>
                        </label>
                      </div>

                      <div class="col-lg-6">

                          <div class="d-flex">
                              <div class="d-flex align-items-center me-3">
                                <input
                                  type="radio"
                                  name="choose"
                                  :checked="allCheck"
                                  @click="allCheck = true"
                                />
                                <label>All</label>
                              </div>
                              <div class="d-flex align-items-center">
                                <input
                                  type="radio"
                                  name="choose"
                                  :checked="!allCheck"
                                  @click="allCheck = false; formData.program=[]"
                                />
                                <label>Select</label>
                              </div>
                            </div>

                            <v-select  v-show="!allCheck"
                            name="program"
                            id="program"
                            :options="moduleList" 
                            label="name"
                            :loading="!moduleList.length"
                            :class="{
                              'p-invalid': this.v$.formData.program.$error,
                            }"
                            v-model="formData.program"
                            :selectable="(data) => selectedProgram(data)"
                            multiple
                            />

                            <v-errors v-show="!allCheck"
                              :vuelidateErrors="{
                                errors: this.v$.formData.program.$errors,
                                value: 'Program',
                              }"
                            ></v-errors>

                      </div>

                    </div>

                    <div class="row mb-3">

                      <div class="col-lg-3">
                        <label for="type"> 
                            Type 
                            <span class="text-danger">*</span>
                        </label>
                      </div>

                      <div class="col-lg-6">

                            <v-select 
                            :clearable="false"
                            name="type"
                            id="type"
                            :options="getType" 
                            label="name"
                            class="border-danger"
                            :class="{
                              'p-invalid': this.v$.formData.type.$error,
                            }"
                            v-model="formData.type"
                            />
                            <!-- :selectable="(val)=> selectableType(val)" -->
                          
                            <v-errors
                              :vuelidateErrors="{
                                errors: this.v$.formData.type.$errors,
                                value: 'Type',
                              }"
                            ></v-errors>

                      </div>

                    </div>

                    <div class="row mb-3" v-show="checkLevel()">

                    <div class="col-lg-3">
                      <label for="remark"> 
                          Level 
                          <span class="text-danger">*</span>
                      </label>
                    </div>

                    <div class="col-lg-6">

                          <v-select 
                          :clearable="false"
                          name="level"
                          id="level"
                          :options="getLevel" 
                          label="name"
                          :class="{
                            'p-invalid': this.v$.formData.level.$error,
                          }"
                          v-model="formData.level"
                          />


                          <v-errors
                            :vuelidateErrors="{
                              errors: this.v$.formData.level.$errors,
                              value: 'Level',
                            }"
                          ></v-errors>

                    </div>

                    </div>

                    <div class="row mb-3">

                    <div class="col-lg-3">
                      <label for="remark"> 
                          Percentage 
                          <span class="text-danger">*</span>
                      </label>
                    </div>

                    <div class="col-lg-6">

                          <input 
                          name="percentage"
                          id="percentage"
                          type="text"  
                          class="form-control" 
                          :class="{
                            'p-invalid': this.v$.formData.percentage.$error,
                          }"
                          v-model="formData.percentage"/>


                          <v-errors
                            :vuelidateErrors="{
                              errors: this.v$.formData.percentage.$errors,
                              value: 'Percentage',
                            }"
                          ></v-errors>

                    </div>

                    </div>
                    
                    
  
                <!-- end row -->
                <div
                  class="row"
                >
                  <!-- <div class="col-6"></div>       -->
                  <div class="col-md-6 mx-auto">
                    <div class="row">
                      <div class="col-2"></div>
                      <div class="col-10">
                        <div class="mt-3 mb-3 ms-3">
                          <router-link :to="{ name: 'assessment-criteria-percentage' }">
                            <button
                              type="button"
                              class="btn w-sm btn-secondary me-5"
                            >
                              Cancel
                            </button>
                          </router-link>
                          <button
                            :disabled="isLoading ? true : false"
                            type="button"
                            class="btn w-sm btn-success waves-effect waves-light px-3"
                            @click="updateAssessmentCriteria()"
                          >
                            <span
                              v-if="btnLoading"
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            {{ btnLoading == true ? "Loading..." : "Save" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
    
  </template>
  
  <script>

  import axios from "axios";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { useToast } from "vue-toastification";
  import { levels } from "../../../const";
  import { types } from "../../../const";

  import { useVuelidate } from '@vuelidate/core'
  import { required } from '@vuelidate/validators'

  // import validationErrors from "../../mixins/validationErrors";

  
  export default {

    setup() {
      const toast = useToast();
      return { v$: useVuelidate(), toast };
    },

    components: {
      vSelect,
    },

    // mixins: [validationErrors],
    data() {

      return {
        // assessment_criteria : [],
        formData : {
            code: null,
            program: [],
            level: null,
            type: null,
            percentage: null
        },
        allCheck : null,
        moduleList : this.$store.getters["odoo/getAllPrograms"],
        getLevel : levels,
        getType : types,
        isLoading: false,
        baseUrl: process.env.VUE_APP_BASE_URL,
         
        btnLoading : false

      };

    },

    validations () {

      return {
        formData : {
            program: this.allCheck ? '' : {required},
            level: this.formData.type?.name == 'Orientation' ? '' : {required},
            type: {required},
            percentage: {required}
        },
      }

    },

    methods: {

      async getAssessmentCriteria() {
        
        this.isLoading = true;

        await axios.get(`${this.baseUrl}admin/v2/assessment-criteria-percentage/${this.$route.params.id}`,)
                    
                    .then((res) => {

                        let data = res.data.data
                      
                        this.formData.code = data.code
                        this.formData.level = data.level != null ? this.getLevel.filter((val) => val.name == data.level)[0] : null
                        this.formData.type = this.getType.filter((val) => val.name == data.type )[0]
                        this.formData.program = data.programs
                        this.formData.percentage = data.percentage
                        this.allCheck = data.all_check
                        this.isLoading = false;
                    }).catch(()=> this.toast.error("Something Went Wrong!"));

      },

      async updateAssessmentCriteria() {

        if(this.allCheck) {
            this.formData.program = this.moduleList.map((val) => val.id)
        }
        const isFormCorrect = await this.v$.$validate()
        
        if (!isFormCorrect) return

        this.btnLoading = true

        let data = {
                program_ids : 
                           this.allCheck ?  this.formData.program
                                         :   this.formData.program.map((val) => val.id),
                type : this.formData.type.name,
                level  : this.checkLevel() ? this.formData.level.name : null,
                percentage : this.formData.percentage,
                all_check : this.allCheck ? 1 : 0
            }
          
        await axios.put(`${this.baseUrl}admin/v2/assessment-criteria-percentage/${this.$route.params.id}`, data)

                    .then(() => {

                        this.btnLoading = false;
                        this.$router.push({ name: "assessment-criteria-percentage" });
                        this.toast.success("Successfully Criteria Updated!");  
                       
                    }).catch(()=> this.toast.error("Something Went Wrong!"));
          
      },

      selectableType(val) {
          return !(val.id == this.formData?.type);
      },
      selectedProgram(option) {

        return (!this.formData.program.length )  ? true :
        this.formData.program.find((p) => p.id == option.id) === undefined

      },

      checkLevel(){
        if(!this.isLoading) {
            if(this.formData.type !== "") {
              if(this.formData.type?.name !== "Orientation") {
                return true;
              }
          }else{
            return false;
          }
        }
        
      }

   

    },

    async created() {

      await this.getAssessmentCriteria()

    },

  };

  </script>
  
  <style>

    .del {
      color: rgb(126, 26, 26);
    }

    .del::hover {
      cursor: pointer;
    }
  
  </style>
  